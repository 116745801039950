.Structure {
  background-color: rgba(0, 0, 0, 0.075);
  /* height: 100vh; */
}

.structure-col {
  text-align: left;
  padding: 2px 2px;
}

.structure-img {
  width: 100%;
  height: auto;
}

.App {
  text-align: center;
  margin-top: 40px;
  background: url("background-blots.png") top no-repeat;
  background-color: rgb(240, 234, 214);
}

a:link, a:visited {
    color: black;
	font-weight: 600;
}

a:hover, a:active {
    color: red;
	text-decoration: none;
}

.App h1 {
  font-family: "Montserrat";
  font-size: 50px;
  font-weight: 900;
}

.App h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 500;
}

.App h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 25 px;
  font-weight: 500;
}

.App .i-hov {
  color: black;
  margin: 10px;
  display: inline-block;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.App .i-hov:hover {
  color: #F27935;
  display: inline-block;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.section {
  padding: 0px 15px;
  background-color: grey;
}

.Welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
}

.Welcome button {
  font-family: "Space Mono";
    background-color: rgba(255,255,255,0.6);
    color: black;
    border: 2px solid black;
    border-radius: 3px;
    padding: 5px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 10px 10px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
}

.Welcome button:hover {
    background-color: #F27935;
    border: 2px solid;
    border-color: #000000;
    color: white;
}

.Welcome h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  display: inline-block;
}

.down-arrow {
  background-color: grey;
}

.main-pic {
  width: 280px;
  padding: 10px;
}

.welcome-intro {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  vertical-align: middle;
  align-items: center;
}

.App strong {
  font-weight: 900;
  color: #F27935;
  #background-color: #F27935;
}

.welcome-box {
	background-color: black;
}

.Projects {
  background-color: rgba(255, 255, 255, 0.7);
}

.Separator {
  background-color: rgba(255, 255, 255, 0.8);
}

.About {
  background-color: rgba(255, 255, 255, 0.7);
}

.nav-name {
  padding: 0px 40px;
  align-items: center;
}

.nav-name h1 {
  font-family: "Montserrat";
  font-weight: 100;
  font-size: 30px;
  padding: 5px 0px;
}

.nav-item {
  align-items: center;
  margin: 0px 5px;
}

.nav-item h1 {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 18px;
}

/* .nav-link { */
  /* margin: 0px 5px; */
  /* -webkit-transition-duration: 0.4s; */
  /* transition-duration: 0.4s; */
/* } */

/* .nav-link:hover:not(.active) { */
  /* background-color: grey; */
/* } */

.Footer {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}

.Footer strong {
	color: #F27935;
}

.Footer .i-hov:hover {
  color: #F27935;
  display: inline-block;
}

.footer-pic {
  width: 75%;
  height: auto;
  padding: 10px;
}

.block {
  background-color: grey;
  color: white;
}

.block h1 {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}
